import React from 'react';
import '../css/Modal.css';  // We will define the CSS for the modal

const Modal = ({ show, onClose, onSave, userEmail, setUserEmail }) => {
  if (!show) return null;

  const defaultEmail = userEmail;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>eMail Shopping List</h2>
        <label>Enter an email to send to and close this list to start a new shopping list.</label>
        <input
          type="text"
          value={defaultEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder={defaultEmail} 
          className="modal-input"
        />
        <div className="modal-buttons">
          <button className="cancel-modal" onClick={onClose}>Cancel</button>
          <button className="save-modal" onClick={onSave} disabled={!userEmail}>Email & Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
