import React, { createContext, useState, useEffect } from 'react';

// Create the UserContext
export const UserContext = createContext();

// Helper function to check if the token is still valid
const parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];  // Split the JWT into parts
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);  // Parse the payload to JSON
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

const isTokenValid = (token) => {
    try {
        const decodedToken = parseJwt(token);
        const currentTime = Date.now() / 1000;  // Get current time in seconds
        //console.log('Decoded Token:', decodedToken);  // Log the decoded token
        //console.log('Token Expiration Time:', decodedToken.exp);
        //console.log('Current Time:', currentTime);
        return decodedToken.exp > currentTime;  // Check if token has expired
        //return true;
    } catch (error) {
        console.error('Token validation error:', error);
        return false;
    }
};

// Create a provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);  // Add loading state

    useEffect(() => {
        // Check if the token exists in localStorage
        const token = localStorage.getItem('token');
        //console.log('Token in localStorage:', token);  // Log token

        if (token) {
            const tokenIsValid = isTokenValid(token);
            //console.log('isTokenValid result:', tokenIsValid);  // Log the result

            if (tokenIsValid) {
                // Fetch user details based on token
                fetch('/api/auth/me', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.fullName) {
                            setUser({
                                fullName: data.fullName,
                                email: data.email,
                                avatar: data.avatar || 'https://via.placeholder.com/150',
                            });
                        } else {
                            console.error('Failed to fetch user details:', data);
                        }
                        setLoading(false);  // Done fetching
                    })
                    .catch(err => {
                        console.error('Error fetching user data:', err);
                        setLoading(false);  // Also mark as done in case of an error
                    });
            } else {
                console.log('No valid token found or token is expired.');
                setLoading(false);  // No token, nothing to fetch, set loading to false
            }
        }
    }, []);

    // Log out function to clear the token and reset user
    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);  // Clear user from context
    };

    return (
        <UserContext.Provider value={{ user, setUser, logout, loading }}>
            {children}
        </UserContext.Provider>
    );
};
